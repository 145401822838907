.donate__container {
  border-radius: 14px;
  background: #fff;
  width: 55vw;
}
@media (max-width: 768px) {
  .donate__container {
    width: 80vw;
  }
}

.donate__container .donate__container__topbar {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  gap: 10rem;
}
@media (max-width: 1060px) {
  .donate__container .donate__container__topbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
  }
}
.donate__container__topbar h3 {
  font-family: 'Playfair Display', serif;
  margin-bottom: 15px;
}
.donate__container__topbar img {
  height: 70px;
  width: 100px;
}

.donate__container__form {
  padding: 20px 45px;
  background: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
@media (max-width: 768px) {
  .donate__container__form {
    padding: 20px 30px;
  }
}
.donate__container__form__switch {
  display: flex;
  align-items: center;
  justify-content: center;
}
.donate__container__form h4 {
  font-family: 'Playfair Display', serif;
  margin: 5px 0;
}
.input__field div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.input__field input {
  border: none;
  border-radius: 14px;
  padding: 10px 15px;
  font-size: 0.9rem;
  color: #000;
  cursor: text;
  width: 91%;
  margin-right: 1rem;
  margin-bottom: 15px;
}
.input__field div input {
  border: none;
  border-radius: 14px;
  padding: 10px 15px;
  font-size: 0.9rem;
  color: #000;
  cursor: text;
  width: 42%;
  margin-right: 1rem;
  margin-bottom: 15px;
}
.donation-details-ko-section h3 {
  font-family: 'Playfair Display', serif;
  color: rgb(39, 38, 38);
  font-size: 20px;
}
.donation-details-ko-section select {
  border: none;
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 0.9rem;
  color: rgb(49, 48, 48);
  margin-bottom: -0.1rem;
  width: 97%;
}
.donation-details-ko-section select:hover {
  cursor: pointer;
}
.donation-details-ko-section input {
  border: none;
  border-radius: 14px;
  padding: 10px 15px;
  font-size: 0.9rem;
  color: #000;
  cursor: text;
  width: 93%;
  margin-top: 1rem;
  margin-bottom: 15px;
}
textarea {
  border-radius: 14px;
  padding: 25px;
  outline: none;
  font-size: 0.9rem;
  width: 90%;
  border: none;
}
.donate__container__form button {
  background: #e74c3c;
  border: none;
  border-radius: 14px;
  padding: 8px;
  width: 97%;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 20px;
}
.donate__container__form button:hover {
  background-color: #f52009;
}

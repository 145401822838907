.forgotPassword {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 0 1rem;
}

.forgotPassword h3 {
    margin-top: 4rem;
    font-weight: 400;
    font-size: 1.5rem;
}

.forgotPassword__content {
    border: 1px rgb(212, 212, 212) solid;
    padding: 1.5rem;
    border-radius: .5rem;
    background-color: white;
}

.forgotPassword__content p {
    margin: 0;
    color: rgb(31, 31, 31);
    line-height: 1.5rem;
    font-weight: 400;
}

.forgotPassword__form > * {
    display: block;
    margin: 1.5rem 0;
}

.forgotPassword__form label p {
    font-weight: 500;
    color: rgb(141, 141, 141);
}

.forgotPassword__form label TextField {
    border: none;
    border-bottom: 1px solid rgb(160, 160, 160);
    background-color: transparent;
    padding: .5rem;
    font-size: 1rem;
    width: 80%;
}

.forgotPassword__form label TextField:hover {
    border-bottom: 2px solid black;
}

.forgotPassword__form label TextField:focus {
    border-bottom: 2px solid rgb(17, 17, 214);
}

.forgotPassword__form button {
    border: none;
    background-color: #d2dcf5;
    padding: .7rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #1976d2;
    border-radius: 5px;
    cursor: pointer;
}

.forgotPassword__form button:hover {
    background-color: #c9d3ee;
}

.forgotPassword__content a {
    color: #1976d2;
    font-weight: 400;
}

.forgotPassword__content a:hover {
    text-decoration: underline;
}

.forgotPassword__content hr {
    border: none;
    height: 1px;
    background-color: rgb(209, 209, 209);
    width: 100%;
    margin: 4rem 0 1rem;
}
